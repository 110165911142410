// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-module__tagContainer__YHFvO9{
    padding: 4px;
    border: 1px solid #cccccc;
}
.style-module__tagContainer__YHFvO9:hover{
    border-color: #0075ff;
}
.style-module__tagContainerInvalid__XsIFpz{
    padding: 4px;
    border: 1px solid #ef5350;
}
.style-module__tagContainerInvalid__XsIFpz:hover{
    border-color: #0075ff;
}
.style-module__tag__IdpHmR{
    position: relative;
    display: block;
    gap: 6px;
    background-color: #E8F5E9;
    padding: 2px 8px;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
    border-radius: 4px;
    max-width: 286px;
    overflow: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 22px; 
}
.style-module__tagRemove__mbajS4{
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 8px;
    top: 8px;
    right: 8px;
    background-color: inherit;
    box-shadow: none;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.style-module__tagWrapper__oZezWW{
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}
.style-module__tag__IdpHmR.style-module__invalidTag__giP7A_{
    background-color: #FFEBEE;
}

.style-module__tagRemove__mbajS4::before{
    content: "";
    background-color: #616161; 
    position: absolute;
    width: 10px;
    height: 2px;
    border-radius: 4px;
    transform: rotate(45deg);
    top: 3px;
    left: 0;
}
.style-module__tagRemove__mbajS4::after{
    content: "";
    background-color: #616161; 
    position: absolute;
    width: 10px;
    height: 2px;
    border-radius: 4px;
    transform: rotate(135deg);
    top: 3px;
    left: 0;
}
.style-module__tagInputField__qGb06h{
    border: none;
    padding-left: 2px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
}
.style-module__tagInput__UwM4YE{
    width: 100%;
}
.style-module__tagInputField__qGb06h:focus{
    border: none!important;
}

.style-module__tagLabel__dzQebr{
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #424242;
    margin-bottom: 8px;
}

.style-module__errorMessage__y1AFqz{
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 200;
    line-height: 16px;
    color: #ef5350;
    padding-top: 4px;
    margin-bottom: 0;
    margin-top: 4px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagContainer": `style-module__tagContainer__YHFvO9`,
	"tagContainerInvalid": `style-module__tagContainerInvalid__XsIFpz`,
	"tag": `style-module__tag__IdpHmR`,
	"tagRemove": `style-module__tagRemove__mbajS4`,
	"tagWrapper": `style-module__tagWrapper__oZezWW`,
	"invalidTag": `style-module__invalidTag__giP7A_`,
	"tagInputField": `style-module__tagInputField__qGb06h`,
	"tagInput": `style-module__tagInput__UwM4YE`,
	"tagLabel": `style-module__tagLabel__dzQebr`,
	"errorMessage": `style-module__errorMessage__y1AFqz`
};
export default ___CSS_LOADER_EXPORT___;
