// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-module__wrapper__foZIgt {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  
  .style-module__inputLabel__wTRSDe {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #424242;
    margin-bottom: 8px;
  }
  
  .style-module__inputFieldWrapper__kItl9s {
    display: flex;
    border: 1px solid #cecece;
    border-radius: 1px;
    padding: 6px 12px 6px 9px;
    transition: border-color 0.1s ease-in-out;
    font-weight: 400;
    line-height: 18px;
  }
  
  .style-module__inputField__KNqt18 {
    width: 100%;
    outline: none;
    box-shadow: none;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    box-sizing: border-box;
    background-color: #ffffff;
    padding: 0;
    border: none!important;
    
  }
  
  .style-module__inputFieldWrapper__kItl9s:focus,
  .style-module__inputFieldWrapper__kItl9s:hover:not(:disabled) {
    border: 1px solid #0075ff !important;
  }
  
  .style-module__inputFieldWrapper__kItl9s:has(input:disabled), .style-module__inputField__KNqt18:disabled  {
    background-color: #F5F5F5;
    cursor: not-allowed;
  }
  
  .style-module__invalid__va4qN0 {
    border: 1px solid #ef5350;
  }
  
  .style-module__inputField__KNqt18::placeholder {
    color: #cecece !important;
  }
  
  .style-module__revealPassword__ln1_QF {
    position: absolute;
    top: 1px;
    right: 9px;
    background-color: transparent;
    border: none;
  }
  
  .style-module__errorMessage__K7GI_A {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 200;
    line-height: 16px;
    color: #ef5350;
    padding-top: 4px;
    margin-bottom: 0;
    margin-top: 4px;
  }
  .style-module__inputDefaultText__C4KfS2{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #cecece;
  }`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style-module__wrapper__foZIgt`,
	"inputLabel": `style-module__inputLabel__wTRSDe`,
	"inputFieldWrapper": `style-module__inputFieldWrapper__kItl9s`,
	"inputField": `style-module__inputField__KNqt18`,
	"invalid": `style-module__invalid__va4qN0`,
	"revealPassword": `style-module__revealPassword__ln1_QF`,
	"errorMessage": `style-module__errorMessage__K7GI_A`,
	"inputDefaultText": `style-module__inputDefaultText__C4KfS2`
};
export default ___CSS_LOADER_EXPORT___;
