// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-module__container__cWbuh6{
    width: 344px;
    padding: 20px 24px 24px 24px;
}

.style-module__buttonContainer__ka0W3c{
    align-items: center;
    display: flex;
    gap: 16px;
}

.style-module__formWrapper__rLdger{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
}

.style-module__formTitle__rMB5CJ{
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    color: #212121;
}
.style-module__errorMessage___YlbZk{
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 200;
    line-height: 16px;
    color: #ef5350;
    padding-top: 4px;
    margin-bottom: 0;
    margin-top: 4px;
}
.style-module__tagLabelWrapper__ZUl_xy{
    display: flex;
    gap: 5px;
    align-items: center;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style-module__container__cWbuh6`,
	"buttonContainer": `style-module__buttonContainer__ka0W3c`,
	"formWrapper": `style-module__formWrapper__rLdger`,
	"formTitle": `style-module__formTitle__rMB5CJ`,
	"errorMessage": `style-module__errorMessage___YlbZk`,
	"tagLabelWrapper": `style-module__tagLabelWrapper__ZUl_xy`
};
export default ___CSS_LOADER_EXPORT___;
