var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Page, SortOrder, Uuid } from "@alconost/nitro-services-api/src/proto/base_pb";
import { getAuthMetadata, grpcRequest } from "../newGrpcUtils";
import { DraftServiceClient } from "@alconost/nitro-services-api/src/proto/draft_service_grpc_web_pb";
import { Draft, DraftId, DraftIds, GetNextDraftsRequest, ListDraftsRequest, SortDraftField, SaveDraftUnregisteredRequest, } from "@alconost/nitro-services-api/src/proto/draft_service_pb";
import { AdvancedOptions, OrderTranslationType, } from "@alconost/nitro-services-api/src/proto/order_service_pb";
export var deleteDraftRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var draftIds = _b.draftIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DraftIds();
                request.setDraftIdsList(draftIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DraftServiceClient,
                        methodName: "deleteDraft",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getDraftAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var draftId = _b.draftId, next = _b.next, field = _b.field, order = _b.order, sourceLanguage = _b.sourceLanguage, format = _b.format, search = _b.search, locale = _b.locale, workspaceName = _b.workspaceName, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetNextDraftsRequest();
                request.setDraftId(draftId);
                request.setNext(next);
                request.setField(SortDraftField[field]);
                request.setOrder(SortOrder[order]);
                request.setSourceLanguagesList(sourceLanguage);
                request.setFormat(format);
                request.setSearch(search);
                request.setLocale(locale);
                request.setWorkspaceName(workspaceName);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DraftServiceClient,
                        methodName: "getDraftAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listDraftAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, metadata;
    var limit = _b.limit, offset = _b.offset, field = _b.field, order = _b.order, sourceLanguage = _b.sourceLanguage, format = _b.format, search = _b.search, locale = _b.locale, workspace = _b.workspace, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListDraftsRequest();
                page = new Page();
                page.setLimit(limit);
                page.setOffset(offset);
                request.setField(SortDraftField[field]);
                request.setOrder(SortOrder[order]);
                request.setSourceLanguagesList(sourceLanguage);
                request.setFormat(format);
                request.setSearch(search);
                request.setLocale(locale);
                request.setPage(page);
                workspace && request.setWorkspaceName(workspace);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DraftServiceClient,
                        methodName: "listDraftAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listDraftCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, metadata;
    var limit = _b.limit, offset = _b.offset, field = _b.field, order = _b.order, sourceLanguage = _b.sourceLanguage, format = _b.format, search = _b.search, locale = _b.locale, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListDraftsRequest();
                page = new Page();
                page.setLimit(limit);
                page.setOffset(offset);
                request.setField(SortDraftField[field]);
                request.setOrder(SortOrder[order]);
                request.setSourceLanguagesList(sourceLanguage);
                request.setFormat(format);
                request.setSearch(search);
                request.setLocale(locale);
                request.setPage(page);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DraftServiceClient,
                        methodName: "listDraftCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getDraftCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var draftId = _b.draftId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DraftId();
                request.setDraftId(draftId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DraftServiceClient,
                        methodName: "getDraftCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var createDraftRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var draft, advancedOptions, metadata;
    var translationService = _b.translationService, draftId = _b.draftId, uuids = _b.uuids, sourceText = _b.sourceText, sourceLanguage = _b.sourceLanguage, targetLanguageList = _b.targetLanguageList, messageText = _b.messageText, imagesIds = _b.imagesIds, translationType = _b.translationType, discountCode = _b.discountCode, maxTranslationsLength = _b.maxTranslationsLength, advancedCategoryValue = _b.advancedCategoryValue, selected = _b.selected, _c = _b.waitOrdersGroupToNotified, waitOrdersGroupToNotified = _c === void 0 ? false : _c, needApplyTmPmDiscount = _b.needApplyTmPmDiscount, token = _b.token;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                draft = new Draft();
                advancedOptions = new AdvancedOptions();
                advancedOptions.setTranslationCategory(advancedCategoryValue);
                advancedOptions.setTranslationType(OrderTranslationType[translationType]);
                advancedOptions.setMaxLength(maxTranslationsLength);
                draftId && draft.setDraftId(draftId);
                draft.setMessageText(messageText);
                draft.setAdvancedOptions(advancedOptions);
                draft.setSourceLanguage(sourceLanguage);
                draft.setTargetLanguagesList(targetLanguageList);
                draft.setAttachmentsList(imagesIds);
                draft.setDiscountCode(discountCode);
                draft.setWaitOrdersGroupToNotified(waitOrdersGroupToNotified);
                selected && draft.setSelected(selected);
                sourceText && draft.setSourceText(sourceText);
                uuids && draft.setFileUuid(uuids);
                draft.setNeedApplyTmPmDiscount(needApplyTmPmDiscount);
                draft.setTranslationService(translationService);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _d.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: DraftServiceClient,
                        methodName: "saveDraft",
                        protoRequest: draft,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var saveDraftUnregisterRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var draft, advancedOptions, req;
    var translationService = _b.translationService, uuids = _b.uuids, sourceText = _b.sourceText, sourceLanguage = _b.sourceLanguage, targetLanguageList = _b.targetLanguageList, messageText = _b.messageText, imagesIds = _b.imagesIds, translationType = _b.translationType, discountCode = _b.discountCode, maxTranslationsLength = _b.maxTranslationsLength, advancedCategoryValue = _b.advancedCategoryValue, selected = _b.selected, _c = _b.waitOrdersGroupToNotified, waitOrdersGroupToNotified = _c === void 0 ? false : _c, needApplyTmPmDiscount = _b.needApplyTmPmDiscount, email = _b.email, locale = _b.locale, draftId = _b.draftId;
    return __generator(this, function (_d) {
        draft = new Draft();
        advancedOptions = new AdvancedOptions();
        req = new SaveDraftUnregisteredRequest();
        advancedOptions.setTranslationCategory(advancedCategoryValue);
        advancedOptions.setTranslationType(OrderTranslationType[translationType]);
        advancedOptions.setMaxLength(maxTranslationsLength);
        draftId && draft.setDraftId(draftId);
        draft.setMessageText(messageText);
        draft.setAdvancedOptions(advancedOptions);
        draft.setSourceLanguage(sourceLanguage);
        draft.setTargetLanguagesList(targetLanguageList);
        draft.setAttachmentsList(imagesIds);
        draft.setDiscountCode(discountCode);
        draft.setWaitOrdersGroupToNotified(waitOrdersGroupToNotified);
        draft.setTranslationService(translationService);
        selected && draft.setSelected(selected);
        sourceText && draft.setSourceText(sourceText);
        uuids && draft.setFileUuid(uuids);
        draft.setNeedApplyTmPmDiscount(needApplyTmPmDiscount);
        req.setDraft(draft);
        req.setEmail(email);
        req.setLocale(locale);
        return [2 /*return*/, grpcRequest({
                clientService: DraftServiceClient,
                methodName: "saveDraftUnregistered",
                protoRequest: req,
                metadata: undefined,
            })];
    });
}); };
export var getDraftUnregisterRequest = function () {
    var args_1 = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args_1[_i] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (uuid) {
        var req;
        if (uuid === void 0) { uuid = ""; }
        return __generator(this, function (_a) {
            req = new Uuid();
            req.setUuid(uuid);
            return [2 /*return*/, grpcRequest({
                    clientService: DraftServiceClient,
                    methodName: "getDraftUnregistered",
                    protoRequest: req,
                    metadata: undefined,
                })];
        });
    });
};
