import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import item from "./css/sidebar-item.css";

const menuItems = [1, 3, 6];

class SidebarItem extends Component {
  state = {
    sidebarInfo: false,
  };

  setSidebarInfo = info => {
    this.setState({
      sidebarInfo: info,
    });
  };

  render() {
    const { onSelect, data, active, numberItem, isAddNumberBlockToSidebar } = this.props;
    const { sidebarInfo, marginCount } = data;
    const hasSpecialStyles = menuItems.includes(numberItem);
    const isWorkspaceSettings = data.id === "workspaceData";
    return (
      <div key={data.id} styleName={active ? "item.active" : "item.container"} onClick={onSelect}>
        <div styleName={"item.left-block"}>
          {isAddNumberBlockToSidebar && (
            <div styleName={`item.number-block ${active ? "item.number-block-active" : ""}`}>{numberItem}</div>
          )}
          <span
            styleName={"item.text"}
            style={{ width: hasSpecialStyles && !isWorkspaceSettings ? "120px" : "inherit" }}
          >
            {data.text}
          </span>
        </div>
        <span styleName={"item.additional"}>{sidebarInfo ? sidebarInfo : this.state.sidebarInfo}</span>
        {marginCount >= 0 && <span styleName={"item.marginCount"}>{marginCount}</span>}
      </div>
    );
  }
}

SidebarItem.propTypes = {};

export default SidebarItem;
