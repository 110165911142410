import { WorkspaceTypes } from "./workspaceActionTypes";
export function requestListWorkspacesAdmin() {
    return {
        type: WorkspaceTypes.LIST_WORKSPACES_ADMIN_REQUEST,
    };
}
export function successListWorkspacesAdmin(payload) {
    return {
        type: WorkspaceTypes.LIST_WORKSPACES_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestCreateWorkspace() {
    return {
        type: WorkspaceTypes.CREATE_WORKSPACE_REQUEST,
    };
}
export function successCreateWorkspace(payload) {
    return {
        type: WorkspaceTypes.CREATE_WORKSPACE_SUCCESS,
        payload: payload,
    };
}
export function requestGetWorkspacesAdmin() {
    return {
        type: WorkspaceTypes.GET_WORKSPACES_ADMIN_REQUEST,
    };
}
export function successGetWorkspacesAdmin(payload) {
    return {
        type: WorkspaceTypes.GET_WORKSPACES_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestGetWorkspace() {
    return {
        type: WorkspaceTypes.GET_WORKSPACE_REQUEST,
    };
}
export function successGetWorkspace(payload) {
    return {
        type: WorkspaceTypes.GET_WORKSPACE_SUCCESS,
        payload: payload,
    };
}
export function disableGetWorkspace() {
    return {
        type: WorkspaceTypes.GET_WORKSPACE_DISABLE,
    };
}
export function requestUpdateAdminWorkspace() {
    return {
        type: WorkspaceTypes.UPDATE_ADMIN_WORKSPACE_REQUEST,
    };
}
export function successUpdateAdminWorkspace(payload) {
    return {
        type: WorkspaceTypes.UPDATE_ADMIN_WORKSPACE_SUCCESS,
        payload: payload,
    };
}
export function requestListWorkspaceNames() {
    return {
        type: WorkspaceTypes.LIST_WORKSPACE_NAMES_REQUEST,
    };
}
export function successListWorkspaceNames(payload) {
    return {
        type: WorkspaceTypes.LIST_WORKSPACE_NAMES_SUCCESS,
        payload: payload,
    };
}
export function requestAcceptInvite() {
    return {
        type: WorkspaceTypes.ACCEPT_INVITE_REQUEST,
    };
}
export function successAcceptInvite(payload) {
    return {
        type: WorkspaceTypes.ACCEPT_INVITE_SUCCESS,
        payload: payload,
    };
}
export function errorAcceptInvite(error) {
    return {
        type: WorkspaceTypes.ACCEPT_INVITE_ERROR,
        payload: error,
    };
}
export function requestDeleteInvite() {
    return {
        type: WorkspaceTypes.DELETE_INVITE_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на удаление приглашения
export function successDeleteInvite(payload) {
    return {
        type: WorkspaceTypes.DELETE_INVITE_SUCCESS,
        payload: payload,
    };
}
// ActionCreator для обработки ошибок запроса на удаление приглашения
export function errorDeleteInvite(error) {
    return {
        type: WorkspaceTypes.DELETE_INVITE_ERROR,
        payload: error,
    };
}
export function requestUpdateWorkspaceAdmin() {
    return {
        type: WorkspaceTypes.ERROR_UPDATE_WORKSPACE_ADMIN,
    };
}
export function successUpdateWorkspaceAdmin(payload) {
    return {
        type: WorkspaceTypes.ERROR_UPDATE_WORKSPACE_ADMIN,
        payload: payload,
    };
}
export function failureUpdateWorkspaceAdmin(error) {
    return {
        type: WorkspaceTypes.ERROR_UPDATE_WORKSPACE_ADMIN,
        error: error,
    };
}
export function requestAddInvitesAdmin() {
    return {
        type: WorkspaceTypes.REQUEST_ADD_INVITES_ADMIN,
    };
}
export function successAddInvitesAdmin(payload) {
    return {
        type: WorkspaceTypes.SUCCESS_ADD_INVITES_ADMIN,
        payload: payload,
    };
}
export function failureAddInvitesAdmin(error) {
    return {
        type: WorkspaceTypes.ERROR_ADD_INVITES_ADMIN,
        error: error,
    };
}
export function requestDeleteUsersAdmin() {
    return {
        type: WorkspaceTypes.REQUEST_DELETE_USERS_ADMIN,
    };
}
export function successDeleteUsersAdmin(payload) {
    return {
        type: WorkspaceTypes.SUCCESS_DELETE_USERS_ADMIN,
        payload: payload,
    };
}
export function failureDeleteUsersAdmin(error) {
    return {
        type: WorkspaceTypes.ERROR_DELETE_USERS_ADMIN,
        error: error,
    };
}
export function requestUpdateUsersWorkspaceAdmin() {
    return {
        type: WorkspaceTypes.REQUEST_UPDATE_USERS_WORKSPACE_ADMIN,
    };
}
export function successUpdateUsersWorkspaceAdmin(payload) {
    return {
        type: WorkspaceTypes.SUCCESS_UPDATE_USERS_WORKSPACE_ADMIN,
        payload: payload,
    };
}
export function failureUpdateUsersWorkspaceAdmin(error) {
    return {
        type: WorkspaceTypes.ERROR_UPDATE_USERS_WORKSPACE_ADMIN,
        error: error,
    };
}
export function requestUpdateEmailNotifications() {
    return {
        type: WorkspaceTypes.REQUEST_UPDATE_EMAIL_NOTIFICATIONS,
    };
}
// ActionCreator для успешного завершения запроса updateEmailNotifications
export function successUpdateEmailNotifications(payload) {
    return {
        type: WorkspaceTypes.SUCCESS_UPDATE_EMAIL_NOTIFICATIONS,
        payload: payload,
    };
}
// ActionCreator для неудачного завершения запроса updateEmailNotifications
export function failureUpdateEmailNotifications(error) {
    return {
        type: WorkspaceTypes.ERROR_UPDATE_EMAIL_NOTIFICATIONS,
        error: error,
    };
}
export function requestUpdateWorkspaceMember() {
    return {
        type: WorkspaceTypes.REQUEST_UPDATE_WORKSPACE_MEMBER,
    };
}
// ActionCreator для успешного завершения запроса updateWorkspaceMember
export function successUpdateWorkspaceMember(payload) {
    return {
        type: WorkspaceTypes.SUCCESS_UPDATE_WORKSPACE_MEMBER,
        payload: payload,
    };
}
// ActionCreator для неудачного завершения запроса updateWorkspaceMember
export function failureUpdateWorkspaceMember(error) {
    return {
        type: WorkspaceTypes.ERROR_UPDATE_WORKSPACE_MEMBER,
        error: error,
    };
}
export function requestDeleteWorkspaceMembers() {
    return {
        type: WorkspaceTypes.REQUEST_DELETE_WORKSPACE_MEMBERS,
    };
}
// ActionCreator для успешного завершения запроса deleteWorkspaceMembersAdmin
export function successDeleteWorkspaceMembers(payload) {
    return {
        type: WorkspaceTypes.SUCCESS_DELETE_WORKSPACE_MEMBERS,
        payload: payload,
    };
}
// ActionCreator для неудачного завершения запроса deleteWorkspaceMembersAdmin
export function failureDeleteWorkspaceMembers(error) {
    return {
        type: WorkspaceTypes.ERROR_DELETE_WORKSPACE_MEMBERS,
        error: error,
    };
}
export function requestUpdateUsersAdmin() {
    return {
        type: WorkspaceTypes.REQUEST_UPDATE_USERS_ADMIN,
    };
}
// ActionCreator для успешного завершения запроса updateUsersAdmin
export function successUpdateUsersAdmin(payload) {
    return {
        type: WorkspaceTypes.SUCCESS_UPDATE_USERS_ADMIN,
        payload: payload,
    };
}
// ActionCreator для неудачного завершения запроса updateUsersAdmin
export function failureUpdateUsersAdmin(error) {
    return {
        type: WorkspaceTypes.ERROR_UPDATE_USERS_ADMIN,
        error: error,
    };
}
// ActionCreator для начала запроса getInviteInfo
export function requestInviteInfo() {
    return {
        type: WorkspaceTypes.REQUEST_INVITE_INFO,
    };
}
// ActionCreator для успешного получения данных
export function successInviteInfo(payload) {
    return {
        type: WorkspaceTypes.SUCCESS_INVITE_INFO,
        payload: payload,
    };
}
// ActionCreator для обработки ошибки
export function failureInviteInfo(error) {
    return {
        type: WorkspaceTypes.ERROR_INVITE_INFO,
        error: error,
    };
}
