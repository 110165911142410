// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message__container-base__cgTyLj {
  display: flex;
  justify-content: left;
  margin: 24px auto 0;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  border: 2px solid #ff9800;
  padding: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 360px;
  box-sizing: border-box;
  background-color: #fff3e0;
}

.error-message__workspace-error__zy_903{
  max-width: 370px;
  display: flex;
  justify-content: left;
  margin: 24px auto 0;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  border: 2px solid #ff9800;
  padding: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  background-color: #fff3e0;
  margin-bottom: 15px;
}

.error-message__container-top-icon__YxeGzi {
  align-items: flex-start;
  margin: 0 auto 32px;
}

.error-message__container-center-text__AnTfL0 {
  margin: 24px auto 16px;
  justify-content: center;
}

.error-message__icon__RNxPnI {
  display: flex;
  flex: none;
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.error-message__text-base__lipAqx {
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  margin: 4px 0 0;
  width: 100%;
}

.error-message__text-normal__Fh20yp {
  white-space: normal;
}

.error-message__text-line-break__CXZlNi {
  white-space: pre-wrap;
  word-break: break-word;
}

.error-message__text-not-found__mx988X {
  white-space: pre-line;
  line-height: 19px;
}

.error-message__text-desktop-non-found__rMmeRw {
  text-align: center;
  white-space: pre-line;
  color: #212121;
  font-weight: 300;
  font-style: normal;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  line-height: 20px;
}

.error-message__non-icon__w_hEQg {
  display: none;
}

.error-message__exit-icon__YDfiVi {
  justify-self: right;
  height: 8px;
  width: 8px;
  min-height: 8px;
  min-width: 8px;
  margin-left: 8px;
  cursor: pointer;
  left: 4px;
  top: -4px;
  position: relative;
}

.error-message__link__DVRnAO {
  color: #1e88e5;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container-base": `error-message__container-base__cgTyLj`,
	"workspace-error": `error-message__workspace-error__zy_903`,
	"container-top-icon": `error-message__container-top-icon__YxeGzi error-message__container-base__cgTyLj`,
	"container-center-text": `error-message__container-center-text__AnTfL0 error-message__container-base__cgTyLj`,
	"icon": `error-message__icon__RNxPnI`,
	"text-base": `error-message__text-base__lipAqx`,
	"text-normal": `error-message__text-normal__Fh20yp error-message__text-base__lipAqx`,
	"text-line-break": `error-message__text-line-break__CXZlNi error-message__text-base__lipAqx`,
	"text-not-found": `error-message__text-not-found__mx988X error-message__text-base__lipAqx`,
	"text-desktop-non-found": `error-message__text-desktop-non-found__rMmeRw error-message__text-base__lipAqx`,
	"non-icon": `error-message__non-icon__w_hEQg`,
	"exit-icon": `error-message__exit-icon__YDfiVi`,
	"link": `error-message__link__DVRnAO`
};
export default ___CSS_LOADER_EXPORT___;
