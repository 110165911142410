import React, { useState } from "react";
import style from "./css/style.module.css";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { WithContext as ReactTags, SEPARATORS } from "react-tag-input";

const RemoveTagButton = props => {
  const { onRemove, className } = props;
  return <button {...props} onClick={onRemove} className={classNames(className, [style.tagRemove])} />;
};

export const TagInput = ({ onChange, value, error, label }) => {
  const [tags, setTags] = useState(value || []);
  const userEmail = useSelector(state => state.me.me.data.email);
  const handleDelete = i => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
    onChange(newTags);
  };

  const handleAddition = tag => {
    const isInvalidEmail = !validateEmail(tag.text);
    const isUserEmail = tag.text === userEmail;

    const seenEmails = new Set();
    const newTags = [...tags, tag].map(elem => {
      const isDuplicate = seenEmails.has(elem.text);
      seenEmails.add(elem.text);

      const isInvalidTag = elem.text === userEmail || !validateEmail(elem.text) || isDuplicate;

      return {
        ...elem,
        className: isInvalidTag ? style.invalidTag : "",
      };
    });

    setTags(newTags);
    onChange(newTags.map(({ text }) => text));
  };

  const validateEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div>
      <p className={style.tagLabel}>{label}</p>
      <ReactTags
        tags={tags}
        autofocus={false}
        handleDelete={handleDelete}
        separators={[SEPARATORS.SPACE, SEPARATORS.ENTER]}
        handleAddition={handleAddition}
        inputFieldPosition="inline"
        allowDragDrop={false}
        allowUnique={false}
        allowDeleteFromEmptyInput={true}
        removeComponent={RemoveTagButton}
        placeholder={tags.length === 0 && "Add user(s)"}
        handleInputBlur={value => handleAddition({ id: value, text: value })}
        classNames={{
          tag: style.tag,
          tags: error ? style.tagContainerInvalid : style.tagContainer,
          selected: style.tagWrapper,
          tagInputField: style.tagInputField,
          tagInput: style.tagInput,
        }}
      />
      {error && <p className={style.errorMessage}>{error}</p>}
    </div>
  );
};
