import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { clearErrors, setFocusOnFirst, showErrors, validate, handleTabFocus } from "../../helpers/common";
import FacebookLogin from "./FacebookLogin";
import GoogleLogin from "./GoogleLogin";
import HtmlTranslate from "../HtmlTranslate";

export default class LoginForm extends Component {
  static propTypes = {
    facebookHandler: PropTypes.func.isRequired,
    validator: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    disablingTrigger: PropTypes.string,
  };
  static defaultProps = {
    relativePath: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: null,
      modalElements: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  componentDidMount() {
    setFocusOnFirst("input#email.form-control");

    const modalElements = document.querySelectorAll(
      "#login-form input#email, #login-form input#password, #login-form button"
    );
    this.setState({ modalElements });
    document.addEventListener("keydown", e => handleTabFocus(e, modalElements));
  }

  componentWillUnmount() {
    const { modalElements } = this.state;
    document.removeEventListener("keydown", e => handleTabFocus(e, modalElements));
  }
  componentDidUpdate(prevProps) {
    const { invitedWorkspaceEmail } = this.props;
    if (prevProps.invitedWorkspaceEmail !== invitedWorkspaceEmail) {
      this.setState({ email: invitedWorkspaceEmail });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.validator();
    clearErrors();
    document.activeElement.blur();

    const form = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    let errors = validate(form);

    if (!errors) {
      if (!this.props.disabled) this.props.handler(form);
    } else {
      showErrors(errors);
    }
  }

  sendWoopraClick() {
    woopra.track('Clicked the "Forgot the password" button', {
      env: process.env.SERVER_NAME,
    });
  }

  render() {
    const { googleHandler, styleForm, relativePath } = this.props;

    return (
      <form
        id="login-form"
        className="nitro-form"
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        style={styleForm}
      >
        <div className="clearfix" style={{ marginBottom: 24 }}>
          <p style={{ marginBottom: 16 }}>
            <HtmlTranslate value="LOGIN_PAGE.LOGIN_FORM.SOCIAL_LOGIN_LABEL" />
          </p>
          <FacebookLogin
            trigger={this.props.disablingTrigger}
            disabled={this.props.disabled}
            formIndex={5}
            handler={this.props.facebookHandler}
          />
          <GoogleLogin
            trigger={this.props.disablingTrigger}
            disabled={this.props.disabled}
            formIndex={6}
            googleHandler={googleHandler}
          />
        </div>
        <div className="form-group">
          <p>
            <HtmlTranslate value="LOGIN_PAGE.LOGIN_FORM.USE_ACCOUNT_NITRO" />
          </p>
        </div>
        <div className="form-group">
          <div className="label-container">
            <HtmlTranslate value="LOGIN_PAGE.LOGIN_FORM.EMAIL_FIELD_LABEL" />
          </div>
          <input className="form-control" name="email" type="text" id="email" value={this.state.email} />
          <p className="error-message">
            <HtmlTranslate value="LOGIN_PAGE.LOGIN_FORM.EMAIL_FIELD_ERROR" />
          </p>
          <p className="error-message custom-error">
            <HtmlTranslate value="LOGIN_PAGE.LOGIN_FORM.INCORRECT_EMAIL_ERROR" />
          </p>
        </div>
        <div className="form-group">
          <div className="label-container">
            <HtmlTranslate value="LOGIN_PAGE.LOGIN_FORM.PASSWORD_FIELD_LABEL" />
          </div>
          <input className="form-control" name="password" type="password" id="password" value={this.state.password} />
          <p className="error-message">
            <HtmlTranslate value="LOGIN_PAGE.LOGIN_FORM.PASSWORD_FIELD_ERROR" />
          </p>
          <div style={{ marginTop: 8 }}>
            <Link
              style={{ textDecoration: "underline", borderBottom: "none" }}
              to={`${relativePath}/forgot-password`}
              onClick={this.sendWoopraClick}
            >
              <HtmlTranslate value="LOGIN_PAGE.LOGIN_FORM.FORGOT_PASSWORD_LINK" />
            </Link>
          </div>
        </div>
        <div className="form-group button-group">
          <button type="submit" className="btn btn-block btn-nitro">
            <HtmlTranslate value="LOGIN_PAGE.LOGIN_FORM.SUBMIT_BUTTON_TEXT" />
          </button>
        </div>
        {this.state.error && <p className="text-danger">{this.state.error}</p>}
      </form>
    );
  }
}
