import { ServiceError } from "@alconost/nitro-services-api/src/proto/error_pb";
export var deserializeServiceError = function (_a) {
    var msg = _a.msg, methodName = _a.methodName, metadata = _a.metadata;
    if (!msg) {
        console.log("deserializeServiceError", { msg: msg, metadata: metadata, methodName: methodName });
    }
    var arrayBufferToBase64 = function (base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    };
    var serviceError = ServiceError.deserializeBinary(arrayBufferToBase64(msg));
    return serviceError.toObject();
};
