var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useEffect, useCallback, useContext } from "react";
import { Outlet } from "react-router";
import { useDispatch } from "react-redux";
import { getCookie, setCookie } from "../../../helpers/common";
import { generateAccessToken } from "../../../services/AuthServices/requests";
import { setIsTokenLoading, setToken, setRefreshToken } from "../../../actions/App";
var AuthContext = React.createContext({
    logout: function () { },
});
var AuthProvider = function (props) {
    var timer = useRef();
    var dispatch = useDispatch();
    var refreshAccessToken = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var refreshToken, data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    refreshToken = getCookie("refresh_token");
                    if (!refreshToken)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    dispatch(setIsTokenLoading(true));
                    return [4 /*yield*/, generateAccessToken(refreshToken)];
                case 2:
                    data = _a.sent();
                    dispatch(setIsTokenLoading(false));
                    dispatch(setToken(data.accessToken));
                    setCookie("auth_token", data.accessToken, 365);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    logout();
                    dispatch(setIsTokenLoading(false));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, []);
    var logout = useCallback(function () {
        dispatch(setToken(null));
        dispatch(setRefreshToken(null));
        setCookie("auth_token", "", -10);
        setCookie("refresh_token", "", -10);
        window.location.href = "/login";
    }, [dispatch]);
    useEffect(function () {
        var authToken = getCookie("auth_token");
        var refreshToken = getCookie("refresh_token");
        if (!authToken && !refreshToken)
            return;
        refreshAccessToken();
        timer.current = setInterval(function () {
            refreshAccessToken();
        }, parseInt(process.env.TOKEN_UPDATE_TIMER));
        return function () {
            if (timer.current) {
                clearInterval(timer.current);
            }
        };
    }, [refreshAccessToken]);
    return (_jsx(AuthContext.Provider, { value: {
            logout: logout,
        }, children: _jsxs(_Fragment, { children: [props.children, _jsx(Outlet, {})] }) }));
};
var useAuth = function () {
    var context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
};
var withAuthProvider = function (Component) {
    var ComponentWithAuthProvider = function (props) {
        var auth = useAuth();
        return _jsx(Component, __assign({}, props, { auth: auth }));
    };
    return ComponentWithAuthProvider;
};
export { useAuth, withAuthProvider };
export default AuthProvider;
