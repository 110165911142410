import { Modifier, SelectionState } from "draft-js";
import EditorState from "draft-js/lib/EditorState";

export const LEXIQA_VALIDATION_MIN_VOLUME = Number(process.env.LEXIQA_LIMIT);
export const LEXIQA_ERRORS_LIMIT = 0;
export const LEXIQA_NO_DATA = "NO_DATA";
export const EMPTY_STRING = " ";

const LOADING = "loading";
const FAILED = "failed";
const SUCCESS = "success";

export const LEXIQA_STATUSES = {
  loading: LOADING,
  failed: FAILED,
  success: SUCCESS,
};

export const LEXIQA_ERROR_CATEGORIES = {
  inconsistencies: "inconsistencies",
  spelling: "spelling",
  specialchardetect: "specialchardetect",
  numbers: "numbers",
};

export const removeLexiqaErrorById = (initialState, warningId) => {
  if (!initialState || !warningId) return;

  const filteredLexiqaWarnings = initialState?.lxqwarnings?.filter(warn => warn.errorid !== warningId);
  const newLexiqaState = { ...initialState, lxqwarnings: filteredLexiqaWarnings };

  return newLexiqaState;
};

export const removeLexiqaErrorAndShiftPositions = ({
  initialState,
  warningId,
  positionsShift,
  initialShiftPosition,
}) => {
  const updatedLexiWarnings = initialState?.lxqwarnings?.reduce((acc, warn) => {
    const { errorid, start: currWarnStart, end: currWarnEnd } = warn;
    const isCurrentWarningShouldBeRemoved = errorid === warningId;
    const isCurrentWarningPositionsShouldBeShifted = currWarnStart > initialShiftPosition;

    if (isCurrentWarningShouldBeRemoved) {
      return acc;
    }

    if (isCurrentWarningPositionsShouldBeShifted) {
      const updatedCurrentWarn = { ...warn, start: currWarnStart + positionsShift, end: currWarnEnd + positionsShift };
      return [...acc, updatedCurrentWarn];
    }

    return [...acc, warn];
  }, []);

  const newLexiqa = { ...initialState, lxqwarnings: updatedLexiWarnings };
  return newLexiqa;
};

export const getLexiqaStateAfterOnChange = ({ initialState, focusOffset, positionShift }) => {
  const updatedLexiWarnings = initialState?.lxqwarnings?.reduce((acc, warn) => {
    const { start: currWarnStart, end: currWarnEnd, category, insource } = warn;
    if (insource) return [...acc, warn];

    const isCurrentWarningShouldBeRemoved = focusOffset >= currWarnStart && focusOffset <= currWarnEnd;
    const isCurrentWarningAfterRemovedOne = currWarnStart > focusOffset;
    const isInconsistencyError = category === LEXIQA_ERROR_CATEGORIES.inconsistencies;

    if (isCurrentWarningShouldBeRemoved && !isInconsistencyError) {
      return acc;
    }

    if (isCurrentWarningAfterRemovedOne) {
      const updatedCurrentWarn = { ...warn, start: currWarnStart + positionShift, end: currWarnEnd + positionShift };
      return [...acc, updatedCurrentWarn];
    }

    return [...acc, warn];
  }, []);

  const newLexiqa = { ...initialState, lxqwarnings: updatedLexiWarnings };

  return newLexiqa;
};

export const getLexiqaStateWithRemovedSourceErrors = (initialState, lastInsertedChar) => {
  const isNumberAsString = /^[0-9]$/.test(lastInsertedChar);

  const filteredLexiqaWarnings = initialState?.lxqwarnings?.filter(warn => {
    const { insource, category } = warn;
    const shouldRemoveNumberError = isNumberAsString && category === LEXIQA_ERROR_CATEGORIES.numbers;
    const shouldRemoveNotNumberError =
      !isNumberAsString &&
      category !== LEXIQA_ERROR_CATEGORIES.numbers &&
      lastInsertedChar !== null &&
      lastInsertedChar !== EMPTY_STRING;

    if (!insource) return true;

    return !(shouldRemoveNumberError || shouldRemoveNotNumberError);
  });

  const newLexiqa = { ...initialState, lxqwarnings: filteredLexiqaWarnings };
  return newLexiqa;
};

export const getLexiqaStateAfterIgnoreAllIdenticalErrors = (initialState, warning) => {
  const clone = structuredClone(initialState);

  const identicalErrors = warning?.nitroIdenticalErrors;
  if (identicalErrors) {
    Object.keys(identicalErrors).forEach(currentSegment => {
      clone[currentSegment].lxqwarnings = clone[currentSegment].lxqwarnings.filter(item => {
        return !identicalErrors[currentSegment].includes(item.errorid);
      });
    });
  }
  return clone;
};

export const getEditorStateWithReplacedWord = ({ state, blockKey, start, end, word }) => {
  const contentState = state.getCurrentContent();
  const selectionState = SelectionState.createEmpty(blockKey).merge({
    anchorOffset: start,
    focusOffset: end,
  });

  const newContentState = Modifier.replaceText(contentState, selectionState, word);
  const newState = EditorState.createWithContent(newContentState);

  return newState;
};

export function getLastInsertedCharacter(newEditorState, oldEditorState) {
  const oldContent = oldEditorState.getCurrentContent();
  const newContent = newEditorState.getCurrentContent();

  if (oldContent !== newContent) {
    const lastChangeType = newEditorState.getLastChangeType();

    if (lastChangeType === "insert-characters") {
      const selectionState = newEditorState.getSelection();
      const startKey = selectionState.getStartKey();
      const startOffset = selectionState.getStartOffset();
      const blockWithNewText = newContent.getBlockForKey(startKey);
      const text = blockWithNewText.getText();

      return text[startOffset - 1];
    }
  }

  return null;
}
