import React, { PureComponent } from "react";
import _ from "lodash";
import TableBody from "./components/TableBody/TableBody";
import TableHeader from "./components/TableHeader/TableHeader";
import More from "../More";
// eslint-disable-next-line no-unused-vars
import table from "./css/table.css";
import Empty from "../refactored/EmptyLabel";
import PropTypes from "prop-types";
export default class Table extends PureComponent {
  static propTypes = {
    textEmpty: PropTypes.string,
    tableContainerStyle: PropTypes.object,
  };

  state = {
    elements: {},
    showMore: false,
    displayEmpty: false,
    tableContainerStyle: {},
  };

  lastClick = null;
  elements = {};

  componentDidMount() {
    this.updateDisplayEmpty();
    this.updateTableContainerStyle();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.content !== this.props.content ||
      prevProps.isLoading !== this.props.isLoading ||
      prevState.displayEmpty !== this.state.displayEmpty
    ) {
      this.updateDisplayEmpty();
      this.updateShowMore();
    }
    if (prevProps.width !== this.props.width || prevProps.tableContainerStyle !== this.props.tableContainerStyle) {
      this.updateTableContainerStyle();
    }
  }

  updateDisplayEmpty = () => {
    const { isLoading, content } = this.props;
    const displayEmpty = !isLoading && (!content || !content.length);
    this.setState({ displayEmpty });
  };

  updateShowMore = () => {
    const { displayShowMore, isLoading } = this.props;
    const showMore = displayShowMore || (!displayShowMore && this.state.displayEmpty) || isLoading;
    this.setState({ showMore });
  };

  updateTableContainerStyle = () => {
    const { width, tableContainerStyle: tableContainerStyleProps } = this.props;
    const tableContainerStyle = width && tableContainerStyleProps ? { ...tableContainerStyleProps, width } : { width };

    this.setState({
      tableContainerStyle: document.documentElement.clientWidth > 640 ? tableContainerStyle : undefined,
    });
  };

  handleSelectedRow = (element, isChecked, isShiftClick) => {
    const { handleSelectedRow, content, selectedRows } = this.props;
    const isSelectedRows = selectedRows.length > 0;

    if (isShiftClick) {
      let clicked = this.elements[element.id];
      let lastClicked = this.lastClick && isSelectedRows ? this.elements[this.lastClick.id] : this.elements[element.id];
      let shiftSelected;

      if (clicked >= lastClicked) {
        shiftSelected = content.slice(lastClicked, clicked + 1);
      } else if (clicked <= lastClicked) {
        shiftSelected = content.slice(clicked, lastClicked + 1).reverse();
      }

      shiftSelected.map(async id => {
        await handleSelectedRow(id);
      });
    } else {
      handleSelectedRow(element);
    }

    this.lastClick = !isShiftClick
      ? { id: element.id, isChecked }
      : this.lastClick
        ? this.lastClick
        : { id: element.id, isChecked };
  };

  addElement = (id, index) => {
    this.elements = {
      ...this.elements,
      [id]: index,
    };
  };

  render() {
    const {
      columns,
      onRowClick,
      content,
      displayShowMore,
      isLoading,
      handlerMore,
      showPausedIcon,
      selectedRows,
      textEmpty,
      sort,
      displayBody,
      handleSort,
      titleMoreButton,
      notDisplayMore,
      extraTableStyles,
      checkedRowStyleName,
      idName,
      notBottomPadding,
      contentSpecific,
      highlighted,
      isOnRowClick,
      trStyle,
      rowIsLink,
      cellBtnHandler,
      idTable,
      isNotScrollWrapper,
      cellStyleName,
      hideTableHeader,
      onClickFileTitle,
      updateWorkspaceMembers,
    } = this.props;
    const tableStyle = { marginBottom: notDisplayMore && 0 };
    extraTableStyles && _.assign(tableStyle, extraTableStyles.tableStyle);
    const { showMore, displayEmpty, tableContainerStyle } = this.state;

    return (
      <div style={tableContainerStyle} styleName={"table.container"}>
        <div styleName={isNotScrollWrapper ? "" : "table.scroll-wrapper"}>
          <table style={tableStyle} styleName={"table.table"}>
            {!hideTableHeader && (
              <TableHeader columns={columns} sort={sort} handleSortTable={handleSort} idName={idTable} />
            )}
            {displayBody && (
              <TableBody
                idName={idName}
                columns={columns}
                content={content}
                contentSpecific={contentSpecific}
                handleSelectedRow={this.handleSelectedRow}
                selectedRows={selectedRows}
                checkedRowStyleName={checkedRowStyleName}
                onRowClick={onRowClick}
                isOnRowClick={isOnRowClick}
                addElement={this.addElement}
                showPausedIcon={showPausedIcon}
                highlighted={highlighted}
                trStyle={trStyle}
                rowIsLink={rowIsLink}
                cellBtnHandler={cellBtnHandler}
                idTable={idTable}
                cellStyleName={cellStyleName}
                onClickFileTitle={onClickFileTitle}
                updateWorkspaceMembers={updateWorkspaceMembers}
              />
            )}
          </table>
        </div>
        {showMore ? (
          <div style={extraTableStyles && extraTableStyles.moreStyle} styleName={"table.more"}>
            <Empty display={displayEmpty} text={textEmpty} />
            <More display={displayShowMore} isLoading={isLoading} title={titleMoreButton} handler={handlerMore} />
          </div>
        ) : (
          !notBottomPadding && <div styleName={"table.bottom-padding"}></div>
        )}
      </div>
    );
  }
}
