import { I18n } from "react-redux-i18n";
import {
  listWorkspacesAdminRequest,
  createWorkspaceRequest,
  getWorkspacesAdminRequest,
  getWorkspaceRequest,
  updateAdminWorkspaceRequest,
  listWorkspaceNamesRequest,
  acceptInviteRequest,
  deleteInviteRequest,
  updateWorkspaceAdminRequest,
  addInvitesAdminRequest,
  deleteUsersAdminRequest,
  updateUsersWorkspaceAdminRequest,
  updateEmailNotificationsRequest,
  updateWorkspaceMemberRequest,
  deleteWorkspaceMembersRequest,
  updateUsersAdminRequest,
  getInviteInfoRequest,
} from "../../../services/WorkspaceServices/requests";
import { showNotification } from "../../Notification";
import {
  requestListWorkspacesAdmin,
  successListWorkspacesAdmin,
  requestCreateWorkspace,
  successCreateWorkspace,
  requestGetWorkspacesAdmin,
  successGetWorkspacesAdmin,
  requestGetWorkspace,
  successGetWorkspace,
  disableGetWorkspace,
  requestUpdateAdminWorkspace,
  successUpdateAdminWorkspace,
  requestListWorkspaceNames,
  successListWorkspaceNames,
  errorAcceptInvite,
  errorDeleteInvite,
  requestAcceptInvite,
  requestDeleteInvite,
  successAcceptInvite,
  successDeleteInvite,
  failureUpdateWorkspaceAdmin,
  requestUpdateWorkspaceAdmin,
  successUpdateWorkspaceAdmin,
  failureAddInvitesAdmin,
  requestAddInvitesAdmin,
  successAddInvitesAdmin,
  failureDeleteUsersAdmin,
  requestDeleteUsersAdmin,
  successDeleteUsersAdmin,
  failureUpdateUsersWorkspaceAdmin,
  requestUpdateUsersWorkspaceAdmin,
  successUpdateUsersWorkspaceAdmin,
  failureUpdateEmailNotifications,
  requestUpdateEmailNotifications,
  successUpdateEmailNotifications,
  failureUpdateWorkspaceMember,
  requestUpdateWorkspaceMember,
  successUpdateWorkspaceMember,
  failureDeleteWorkspaceMembers,
  failureUpdateUsersAdmin,
  requestDeleteWorkspaceMembers,
  requestUpdateUsersAdmin,
  successDeleteWorkspaceMembers,
  successUpdateUsersAdmin,
  failureInviteInfo,
  requestInviteInfo,
  successInviteInfo,
} from "./workspaceActionCreators";
import { localStorageService } from "../../../services/LocalStorageService";
import { showWarningErrorNotificationWithClose } from "../../../actions/NotificationActions";

export const listWorkspacesAdminAction =
  ({ limit, offset, search, sortField, sortOrder }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestListWorkspacesAdmin());

    const data = await listWorkspacesAdminRequest({ limit, offset, search, sortField, sortOrder, token });
    dispatch(successListWorkspacesAdmin(data));
    return data;
  };

export const createWorkspaceAction =
  ({ name, link, invites }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestCreateWorkspace());
    try {
      const data = await createWorkspaceRequest({ name, link, invites, token });
      localStorageService.setWorkspaceId(data.id);
      dispatch(showNotification(I18n.t("CUSTOMER_APP.WORKSPACE.SUCCESS_CREATE", { workspaceName: name })));
      dispatch(successCreateWorkspace(data));
      return data;
    } catch (e) {
      throw e;
    }
  };

export const getWorkspacesAdminAction =
  ({ workspaceId }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetWorkspacesAdmin());

    const data = await getWorkspacesAdminRequest({ workspaceId, token });
    dispatch(successGetWorkspacesAdmin(data));
    return data;
  };

export const getWorkspaceAction =
  ({ id, link, name }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();
    if (!(id || link || name)) {
      localStorageService.setWorkspaceId("");
      return dispatch(disableGetWorkspace());
    }

    dispatch(requestGetWorkspace());
    const data = await getWorkspaceRequest({ id, link, name, token });
    localStorageService.setWorkspaceId(data.id);
    dispatch(successGetWorkspace(data));
    return data;
  };

export const updateAdminWorkspaceAction =
  ({ workspaceId, name, link, billTo, balanceOverdraftAllowed }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateAdminWorkspace());

    const data = await updateAdminWorkspaceRequest({
      workspaceId,
      name,
      link,
      billTo,
      balanceOverdraftAllowed,
      token,
    });
    dispatch(showNotification(I18n.t("ADMIN_APP.USERS_PAGE.EDIT_USER_SECTION.SUCCESS_MESSAGE")));
    dispatch(successUpdateAdminWorkspace(data));
    return data;
  };

export const listWorkspaceNamesAction = () => async (dispatch, getState) => {
  const {
    app: { token },
  } = getState();

  dispatch(requestListWorkspaceNames());

  const data = await listWorkspaceNamesRequest(token);
  dispatch(successListWorkspaceNames(data));
  return data;
};

export const acceptInviteAction = workspaceId => async (dispatch, getState) => {
  const {
    app: { token },
  } = getState();

  dispatch(requestAcceptInvite());

  try {
    const data = await acceptInviteRequest({ workspaceId, token });
    dispatch(successAcceptInvite(data));
    return data;
  } catch (error) {
    dispatch(errorAcceptInvite(error));
    throw error;
  }
};

export const deleteInviteAction = workspaceId => async (dispatch, getState) => {
  const {
    app: { token },
  } = getState();

  dispatch(requestDeleteInvite());

  try {
    const data = await deleteInviteRequest({ workspaceId, token });
    dispatch(successDeleteInvite(data));
    return data;
  } catch (error) {
    dispatch(errorDeleteInvite(error));
    throw error;
  }
};

export const updateWorkspaceAdminAction =
  ({ workspaceId, name, link, currency }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateWorkspaceAdmin());

    try {
      const data = await updateWorkspaceAdminRequest({
        workspaceId,
        name,
        link,
        currency,
        token,
      });
      dispatch(successUpdateWorkspaceAdmin(data));
      dispatch(showNotification(I18n.t("CUSTOMER_APP.WORKSPACE.SUCCESS_MESSAGE")));
      return data;
    } catch (error) {
      dispatch(failureUpdateWorkspaceAdmin(error));
      throw error;
    }
  };

export const addInvitesAdminAction =
  ({ workspaceId, emails }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestAddInvitesAdmin());

    try {
      const data = await addInvitesAdminRequest({ workspaceId, emails, token });
      dispatch(showNotification(I18n.t("CUSTOMER_APP.WORKSPACE.SUCCESS_MESSAGE")));
      dispatch(successAddInvitesAdmin(data));
      return data;
    } catch (error) {
      dispatch(failureAddInvitesAdmin(error));
      throw error;
    }
  };

export const deleteUsersAdminAction =
  ({ workspaceId, invitations, users }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestDeleteUsersAdmin());

    try {
      const data = await deleteUsersAdminRequest({
        workspaceId,
        invitations,
        users,
        token,
      });
      dispatch(successDeleteUsersAdmin(data));
      dispatch(showNotification(I18n.t("CUSTOMER_APP.WORKSPACE.SUCCESS_MESSAGE")));
      return data;
    } catch (error) {
      dispatch(failureDeleteUsersAdmin(error));
      throw error;
    }
  };

export const updateUsersWorkspaceAdminAction =
  ({ workspaceId, users }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateUsersWorkspaceAdmin());

    try {
      const data = await updateUsersWorkspaceAdminRequest({
        workspaceId,
        users,
        token,
      });
      dispatch(successUpdateUsersWorkspaceAdmin(data));
      dispatch(showNotification(I18n.t("CUSTOMER_APP.WORKSPACE.SUCCESS_MESSAGE")));
      return data;
    } catch (error) {
      dispatch(failureUpdateUsersWorkspaceAdmin(error));
      throw error;
    }
  };

export const updateEmailNotificationsAction =
  ({ workspaceId, disableEmailNotifications }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateEmailNotifications());

    try {
      const data = await updateEmailNotificationsRequest({
        workspaceId,
        disableEmailNotifications,
        token,
      });
      dispatch(successUpdateEmailNotifications(data));
      dispatch(showNotification(I18n.t("CUSTOMER_APP.WORKSPACE.SUCCESS_MESSAGE")));
      return data;
    } catch (error) {
      dispatch(failureUpdateEmailNotifications(error));
      throw error;
    }
  };

export const updateWorkspaceMemberAction =
  ({ workspaceId, currency }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateWorkspaceMember());

    try {
      const data = await updateWorkspaceMemberRequest({
        workspaceId,
        currency,
        token,
      });
      dispatch(successUpdateWorkspaceMember(data));
      dispatch(showNotification(I18n.t("CUSTOMER_APP.WORKSPACE.SUCCESS_MESSAGE")));
      return data;
    } catch (error) {
      dispatch(failureUpdateWorkspaceMember(error));
      throw error;
    }
  };

export const deleteWorkspaceMembersAction =
  ({ workspaceId, invitationsList, usersList }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestDeleteWorkspaceMembers());

    try {
      const data = await deleteWorkspaceMembersRequest({
        workspaceId,
        invitationsList,
        usersList,
        token,
      });
      dispatch(successDeleteWorkspaceMembers(data));
      dispatch(showNotification(I18n.t("CUSTOMER_APP.WORKSPACE.SUCCESS_MESSAGE")));
      return data;
    } catch (error) {
      dispatch(failureDeleteWorkspaceMembers(error));
      throw error;
    }
  };

export const updateUsersAdminAction =
  ({ workspaceId, usersList }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateUsersAdmin());

    try {
      const data = await updateUsersAdminRequest({
        workspaceId,
        usersList,
        token,
      });
      dispatch(successUpdateUsersAdmin(data));
      dispatch(showNotification(I18n.t("CUSTOMER_APP.WORKSPACE.SUCCESS_MESSAGE")));
      return data;
    } catch (error) {
      dispatch(failureUpdateUsersAdmin(error));
      throw error;
    }
  };

export const getInviteInfoAction = code => async (dispatch, getState) => {
  const {
    app: { token },
  } = getState();

  dispatch(requestInviteInfo());

  try {
    const data = await getInviteInfoRequest({
      code,
      token,
    });
    dispatch(successInviteInfo(data));
    return data;
  } catch (error) {
    dispatch(showWarningErrorNotificationWithClose(I18n.t("CUSTOMER_APP.WORKSPACE.ERROR_LINK")));
    dispatch(failureInviteInfo(error));
    throw error;
  }
};
