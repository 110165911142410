// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-cell__cell__wJOB4c {
  font-size: 14px;
  color: #424242;
  font-weight: 400;
  line-height: 21px;
  border: none !important;
  vertical-align: middle !important;
}

.table-cell__cell-container__tkV3IU {
  display: flex;
  gap: 6px;
  height: 100%;
}
.table-cell__cell-container--custom__N40aJq {
  flex-direction: column;
  gap: 1px;
  padding: 9px 0;
}
.table-cell__cell-container__tkV3IU span {
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
span.table-cell__cell-user-name__EsrwMd{
  color: #9E9E9E;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}
.table-cell__cell-padding__jNWkJZ {
  padding: 8px;
}

table tbody tr td {
  padding: 0 !important;
}

table tbody tr td a {
  display: block;
  width: 100%;
  height: 100%;
}

.table-cell__container__oEnwsl {
  position: relative;
  font-family: "Roboto";
  color: #424242;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
  border: none;
  display: block;
}

.table-cell__container__oEnwsl:hover,
  .table-cell__container__oEnwsl:focus {
    color: #424242;
    -webkit-text-decoration: none;
    text-decoration: none;
    border: none;
  }

.table-cell__container__oEnwsl > span {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    padding-right: 17px;
}

.table-cell__container__oEnwsl > span:before {
    content: attr(data-notificaton);
    display: block;
    position: absolute;
    top: 0px;
    right: -2px;
    color: #ef5350;
    font-family: Roboto, sans-serif;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 10px;
    width: 17px;
}

.table-cell__container__oEnwsl > span[data-notificaton=""]:before {
  display: none;
}

.table-cell__container-glossary__LkvjYE {
  height: 100% !important;
  display: block;
  overflow: hidden;
  position: relative;
}

.table-cell__container-glossary-is-one__cM9SMQ {
  height: 100% !important;
  display: block;
  overflow: hidden;
  position: relative;
  /*max-height: 38px;*/
}

.table-cell__first__dUiJIS {
  margin-left: 1px;
  margin-top: 4px;
  height: 11px;
  width: 15px;
  color: #ef5350;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11px;
}

.table-cell__discount__fKjf_j {
  margin-left: 1px;
  margin-top: -13px;
  color: #ef5350;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11px;
  width: 8px;
}

.table-cell__cell-with-border__i6nfms {
  font-size: 14px;
  color: #424242;
  font-weight: normal;
  border: 1px solid #e0e0e0;
}

tr:first-child:last-child td.table-cell__cell-with-border__i6nfms {
  min-height: 52px;
  height: 52px;
}

.table-cell__container-glossary__LkvjYE:focus-within {
  outline: 1px solid #449ce9 !important;
}

.table-cell__cell-with-border-checked__UPRvSJ {
  border: 1px solid #e3f2fd;
}

.table-cell__cell-with-border-checked__UPRvSJ:focus-within {
  outline: none !important;
}

.table-cell__column-id__X4hgdT {
  display: flex;
}

.table-cell__settings-checkbox__slSArG {
  vertical-align: top !important;
}

.table-cell__column-id__X4hgdT > a {
  width: 74px;
  cursor: pointer;
}

.table-cell__column-amount__rBEBuk {
  text-align: right;
}

.table-cell__column-amount__rBEBuk > a {
  padding-right: 74px;
}

.table-cell__column-created__gTEaHo {
  width: 100%;
  cursor: pointer;
  padding-left: 14px;
}

.table-cell__column-status__yDiZ2R {
  min-width: 100%;
}

.table-cell__column-status__yDiZ2R > a {
  white-space: nowrap;
  cursor: pointer;
}

.table-cell__margin__opdEa8 {
  padding-left: 16px !important;
}

@media (max-width: 658px) {
  .table-cell__column-amount__rBEBuk > a {
    padding-right: 44px;
  }
}

@-moz-document url-prefix() {
  .table-cell__cell-with-border__i6nfms {
  }

  .table-cell__cell-with-border-checked__UPRvSJ {
  }

  .table-cell__container-glossary__LkvjYE {
    display: table;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100% !important;
    width: 100%;
  }

  .table-cell__container-glossary__LkvjYE:focus-within {
    height: 100% !important;
    outline: 1px solid #449ce9 !important;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": `table-cell__cell__wJOB4c`,
	"cell-container": `table-cell__cell-container__tkV3IU`,
	"cell-container--custom": `table-cell__cell-container--custom__N40aJq`,
	"cell-user-name": `table-cell__cell-user-name__EsrwMd`,
	"cell-padding": `table-cell__cell-padding__jNWkJZ`,
	"container": `table-cell__container__oEnwsl`,
	"container-glossary": `table-cell__container-glossary__LkvjYE`,
	"container-glossary-is-one": `table-cell__container-glossary-is-one__cM9SMQ`,
	"first": `table-cell__first__dUiJIS`,
	"discount": `table-cell__discount__fKjf_j`,
	"cell-with-border": `table-cell__cell-with-border__i6nfms`,
	"cell-with-border-checked": `table-cell__cell-with-border-checked__UPRvSJ table-cell__cell-with-border__i6nfms`,
	"column-id": `table-cell__column-id__X4hgdT`,
	"settings-checkbox": `table-cell__settings-checkbox__slSArG`,
	"column-amount": `table-cell__column-amount__rBEBuk`,
	"column-created": `table-cell__column-created__gTEaHo`,
	"column-status": `table-cell__column-status__yDiZ2R`,
	"margin": `table-cell__margin__opdEa8`
};
export default ___CSS_LOADER_EXPORT___;
