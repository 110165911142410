import React, { Component } from "react";
import TableRow from "../TableRow/TableRow";
import * as _ from "lodash";

export default class TableBody extends Component {
  render() {
    const {
      idName,
      columns,
      onRowClick,
      content,
      handleSelectedRow,
      selectedRows,
      isChecked,
      rowStyleName,
      tdStyleName,
      cellStyleName,
      tbodyStyle,
      checkedRowStyleName,
      addElement,
      contentSpecific,
      isOnRowClick,
      showPausedIcon,
      highlighted,
      trStyle,
      rowIsLink,
      cellBtnHandler,
      idTable,
      onClickFileTitle,
      updateWorkspaceMembers,
    } = this.props;

    return (
      <tbody style={tbodyStyle}>
        {content &&
          content.map((item, i) => {
            if (!_.isEmpty(contentSpecific)) {
              item = {
                ...item[contentSpecific[0]],
                [contentSpecific[1]]: item[contentSpecific[1]],
              };
            }

            addElement && addElement(item.id, i);

            return (
              <TableRow
                key={i}
                rowStyleName={rowStyleName}
                checkedRowStyleName={checkedRowStyleName}
                handleSelectedRow={handleSelectedRow}
                columns={columns}
                content={item}
                isChecked={
                  isChecked
                    ? isChecked
                    : _.includes(selectedRows, idName ? item[idName] : item.id ? item.id : item.email)
                }
                onRowClick={onRowClick}
                isOnRowClick={isOnRowClick}
                tdStyleName={tdStyleName}
                cellStyleName={cellStyleName}
                showPausedIcon={showPausedIcon}
                highlighted={highlighted}
                trStyle={trStyle}
                idName={idName}
                rowIsLink={rowIsLink}
                cellBtnHandler={cellBtnHandler}
                idTable={idTable}
                onClickFileTitle={onClickFileTitle}
                updateWorkspaceMembers={updateWorkspaceMembers}
              />
            );
          })}
      </tbody>
    );
  }
}
