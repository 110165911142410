import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import cell from "./css/table-cell.css";
import smallPen from "../../../../images/svg/small-pen.svg";
import { HandySvg } from "handy-svg";
import sheetsIcon from "../../../../images/svg/sheets-icon.svg";
import docsIcon from "../../../../images/svg/docs-icon.svg";
import otherDocsIcon from "../../../../images/svg/other-docs-icon.svg";
import { I18n } from "react-redux-i18n";
import { WorkspaceRoles } from "../WorkspaceRoles/WorkspaceRoles";

export default class TableCell extends PureComponent {
  static propTypes = {
    content: PropTypes.any.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onCellClick: PropTypes.func,
    isFirst: PropTypes.bool,
    isDiscount: PropTypes.bool,
    origin: PropTypes.bool,
    style: PropTypes.object,
    tdStyleName: PropTypes.string,
    cellStyleName: PropTypes.string,
    tdStyle: PropTypes.object,
    tdClass: PropTypes.string,
    optionsFormat: PropTypes.func,
    tooltipFormat: PropTypes.func,
    highlighted: PropTypes.bool,
    rowIsLink: PropTypes.string,
    onClickEditFile: PropTypes.func,
    mainCharsCounter: PropTypes.number,
    subCharsCounter: PropTypes.number,
    charsCounter: PropTypes.number,
    docsType: PropTypes.string,
    onClickFileTitle: PropTypes.func,
    subField: PropTypes.string,
    isWorkspaceRole: PropTypes.bool,
  };

  static defaultProps = {
    tdStyleName: "cell.cell",
    cellStyleName: "cell.container",
    style: {},
  };

  getTooltip = () => {
    const { content, tooltipFormat } = this.props;
    return tooltipFormat ? tooltipFormat(content) : typeof content === "object" ? "" : content;
  };

  getSubCharsText = () => {
    const { mainCharsCounter, subCharsCounter, charsCounter, docsType } = this.props;

    const texts = {
      XML: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.COMPUTER_FILES_DATA", {
        nodeCounter: mainCharsCounter,
        attributesCounter: subCharsCounter,
        charsCounter,
      }),
      GOOGLE_SPREADSHEET: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.GOOGLE_FILES_DATA", {
        sheetCounter: mainCharsCounter,
        columnCounter: subCharsCounter,
        charsCounter,
      }),
      CSV: I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.CSV_FILES_DATA", {
        columnCounter: subCharsCounter,
        charsCounter,
      }),
    };
    return texts[docsType] || "";
  };

  renderIcon = () => {
    const { docsType, content } = this.props;
    if (!docsType || typeof content !== "string") return null;

    const icons = {
      GOOGLE_SPREADSHEET: sheetsIcon,
      GOOGLE_DOC: docsIcon,
      default: otherDocsIcon,
    };

    return <HandySvg width="16" height="16" src={icons[docsType] || icons.default} />;
  };

  render() {
    const {
      id,
      content,
      style,
      tdStyle,
      tdClass,
      onCellClick,
      tdStyleName,
      cellStyleName,
      optionsFormat,
      isFirst,
      isDiscount,
      origin,
      rowIsLink,
      onClickEditFile,
      mainCharsCounter,
      subCharsCounter,
      charsCounter,
      onClickFileTitle,
      subField,
      isWorkspaceRole,
      updateWorkspaceMembers,
    } = this.props;
    const tooltip = this.getTooltip();
    const viewContent = optionsFormat ? optionsFormat(content) : content;
    const dataNotification = isFirst ? "1st" : isDiscount ? "%" : origin ? "API" : "";

    return (
      <td style={tdStyle} styleName={tdClass ? `${tdClass} ${tdStyleName}` : tdStyleName} onClick={onCellClick}>
        {rowIsLink ? (
          <Link to={`${rowIsLink}${this.props.id}`} style={style} styleName={cellStyleName}>
            <div title={tooltip} styleName={cellStyleName}>
              <span data-notification={dataNotification}>{viewContent}</span>
            </div>
          </Link>
        ) : isWorkspaceRole ? (
          <WorkspaceRoles workspaceRole={viewContent} onChange={updateWorkspaceMembers} workspaceId={id} />
        ) : (
          <div style={{ paddingRight: 8, ...style }} styleName={cellStyleName} title={tooltip}>
            <div
              styleName={`cell.cell-container ${subField ? "cell.cell-container--custom" : ""}`}
              style={onClickFileTitle && { cursor: "pointer" }}
              onClick={onClickFileTitle}
            >
              {this.renderIcon()}
              <span data-notification={dataNotification}>{viewContent}</span>
              {subField && <span styleName="cell.cell-user-name">{subField}</span>}
            </div>
            {mainCharsCounter >= 0 && subCharsCounter >= 0 && charsCounter && (
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <p style={{ margin: 0, fontWeight: 300, color: "#9E9E9E", fontSize: "14px" }}>
                  {this.getSubCharsText()}
                </p>
                <HandySvg
                  width="10"
                  height="10"
                  src={smallPen}
                  onClick={onClickEditFile}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}
          </div>
        )}
      </td>
    );
  }
}
