export const AIOption = { value: "AI", label: "CUSTOMER_APP.NEW_ORDER_PAGE.SERVICE.AI" };
export const translationServiceOptionsAuth = [
  { value: "TRANSLATOR", label: "CUSTOMER_APP.NEW_ORDER_PAGE.SERVICE.TRANSLATOR" },
  { value: "PROOFREAD", label: "CUSTOMER_APP.NEW_ORDER_PAGE.SERVICE.PROOFREAD" },
];
export const ORDER_TYPES_BY_SELECTED_SERVICE = {
  TRANSLATOR: "CLASSIC",
  PROOFREAD: "CLASSIC",
  AI: "AI_PROOFREAD",
};

export const SERVICE_NUMBER_BY_VALUE = {
  TRANSLATOR: 1,
  AI: 2,
  PROOFREAD: 3,
};

export const SERVICE_VALUE_BY_NUMBER = {
  1: "TRANSLATOR",
  2: "AI",
  3: "PROOFREAD",
};

export const TRANSLATION_BY_SERVICE_NUMBER = {
  0: "ORDER_PAGE.TRANSLATOR",
  1: "ORDER_PAGE.TRANSLATOR",
  2: "ORDER_PAGE.AI",
  3: "ORDER_PAGE.PROOFREAD",
};
