// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-module__roleEmpty__eW_Fmn{
    padding-right: 24px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roleEmpty": `style-module__roleEmpty__eW_Fmn`
};
export default ___CSS_LOADER_EXPORT___;
