import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import message from "./css/error-message.css";
import { I18n } from "react-redux-i18n";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";
import colorIconSet from "../../../images/color_icons.json";

const TEXT_LINE_HEIGHT = 16;
const ONE_LINE_PADDING = 8;
const MULTI_LINE_PADDING = 4;

class ErrorMessage extends Component {
  static defaultProps = {
    containerStyle: "message.container-top-icon",
    iconStyle: "message.icon",
    textStyle: "message.text-normal",
    exitIconStyle: "message.exit-icon",
  };

  state = {
    messageMarginTop: 6,
    showErrorMessage: true,
  };

  componentDidMount() {
    const messageMarginTop = this.defineMessageMarginTop();
    this.setState({ messageMarginTop });
  }

  defineMessageMarginTop = () => {
    const { message } = this.props;
    const height = this.messageElement && this.messageElement.clientHeight;
    const numberOfLines = message && height / TEXT_LINE_HEIGHT;
    return numberOfLines > 1.4 ? MULTI_LINE_PADDING : ONE_LINE_PADDING;
  };

  handleRetry = () => {
    this.props.handleRetry();
  };

  render() {
    const {
      containerStyle,
      iconStyle,
      textStyle,
      style,
      message,
      exitIconStyle,
      onClose,
      handleRetry,
      isHideExit,
      messageTextStyle,
    } = this.props;
    const { messageMarginTop } = this.state;
    const messageStyle = {
      marginTop: messageMarginTop,
      marginBottom: messageMarginTop,
      marginRight: 4,
      ...(messageTextStyle ?? {}),
    };
    const exitButtonStyle = isHideExit ? { display: "none" } : {};
    return (
      <div style={style} styleName={containerStyle}>
        <i styleName={iconStyle}>
          <IcomoonReact iconSet={colorIconSet} size={28} icon={"i_orange"} />
        </i>
        <p ref={element => (this.messageElement = element)} styleName={textStyle} style={messageStyle}>
          {message.message ? message.message : message}
          <a styleName={"message.link"} style={{ display: handleRetry ? "initial" : "none" }} onClick={handleRetry}>
            {" " + I18n.t("ERRORS.IMAGE_RETRY_UPLOAD")}
          </a>
        </p>
        <IcomoonReact
          iconSet={iconSet}
          color={"#FF9800"}
          size={8}
          icon={"close"}
          styleName={exitIconStyle}
          onClick={onClose}
          style={exitButtonStyle}
        />
      </div>
    );
  }
}

ErrorMessage.propTypes = {};

export default ErrorMessage;
