import React, { Component, Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import block from "./css/table-block.css";
import Table from "../../Table/Table";
import SearchInput from "../SearchInput/SearchInput";
import Toolbar from "../ToolBar/ToolBar";
import PropTypes from "prop-types";
import ErrorMessage from "../ErrorMessage";

class TableBlock extends Component {
  static propTypes = {
    resetSelected: PropTypes.func,
    textNumberSelected: PropTypes.func,
    handleDelete: PropTypes.func,
    updateWorkspaceMembers: PropTypes.func,
    titleBasketToolBar: PropTypes.string,
    toolbarStyle: PropTypes.object,
    tableBlockContainerStyle: PropTypes.object,
    tableContainerStyle: PropTypes.object,
    detail: PropTypes.oneOfType([
      PropTypes.shape({
        items: PropTypes.array,
      }),
      PropTypes.oneOf([null]),
    ]),
    columns: PropTypes.array.isRequired,
    handleSort: PropTypes.func,
    content: PropTypes.array,
    displayShowMore: PropTypes.bool,
    displayBody: PropTypes.bool,
    isLoading: PropTypes.bool,
    sort: PropTypes.object,
    handlerMore: PropTypes.func,
    placeholder: PropTypes.string,
    searchInitContent: PropTypes.string,
    handleSearch: PropTypes.func,
    resetSearch: PropTypes.func,
    handleSelectedRow: PropTypes.func,
    selectedRows: PropTypes.array,
    onRowClick: PropTypes.func,
    textEmpty: PropTypes.string,
    cellBtnHandler: PropTypes.func,
  };

  render() {
    const {
      columns,
      handleSort,
      content,
      displayShowMore,
      isLoading,
      handlerMore,
      placeholder,
      handleSearch,
      resetSearch,
      handleSelectedRow,
      selectedRows,
      onRowClick,
      textEmpty,
      resetSelected,
      textNumberSelected,
      detail,
      sort,
      displayBody,
      titleBasketToolBar,
      toolbarStyle,
      tableBlockContainerStyle,
      searchInitContent,
      width,
      notShowToolBar,
      tableContainerStyle,
      titleMoreButton,
      notDisplayMore,
      extraTableStyles,
      checkedRowStyleName,
      idName,
      closeErrorMessage,
      error,
      errorMessage,
      contentSpecific,
      isOnRowClick,
      showPausedIcon,
      highlighted,
      trStyle,
      rowIsLink,
      basketStyle,
      cellBtnHandler,
      idTable,
      detailObject,
      isNotScrollWrapper,
      cellStyleName,
      hideTableHeader,
      iconBtns,
      onClickFileTitle,
      updateWorkspaceMembers,
      errorContainerStyle,
    } = this.props;
    const positionToolbar = handleSearch ? { justifyContent: "flex-end" } : { justifyContent: "center" };
    const isSelect = selectedRows && selectedRows.length > 0;
    const barContainerStyle = { ...tableBlockContainerStyle, position: isSelect ? "sticky" : "initial" };

    return (
      <Fragment>
        {!notShowToolBar && (
          <div style={barContainerStyle} styleName={"block.bar-container"}>
            {((iconBtns && iconBtns.length > 0) || (detail && detail.items && detail.items.length > 0)) &&
              selectedRows &&
              selectedRows.length > 0 && (
                <div styleName={"block.toolbar"} style={positionToolbar}>
                  <Toolbar
                    titleBasket={titleBasketToolBar}
                    basketStyle={basketStyle}
                    resetSelected={resetSelected}
                    text={textNumberSelected && textNumberSelected()}
                    containerStyle={toolbarStyle}
                    detail={detail}
                    detailObject={detailObject}
                    iconBtns={iconBtns}
                    isSelect={isSelect}
                  />
                </div>
              )}
            {handleSearch && (
              <SearchInput
                handleSearch={handleSearch}
                searchInitContent={searchInitContent}
                resetSearch={resetSearch}
                placeholder={placeholder}
              />
            )}
          </div>
        )}
        {error && (
          <ErrorMessage onClose={closeErrorMessage} message={errorMessage} containerStyle={errorContainerStyle} />
        )}
        <Table
          columns={columns}
          idName={idName}
          sort={sort}
          handleSort={handleSort}
          content={content}
          contentSpecific={contentSpecific}
          displayShowMore={displayShowMore}
          isLoading={isLoading}
          displayBody={displayBody}
          handlerMore={handlerMore}
          handleSelectedRow={handleSelectedRow}
          selectedRows={selectedRows}
          idTable={idTable}
          checkedRowStyleName={checkedRowStyleName}
          onRowClick={onRowClick}
          isOnRowClick={isOnRowClick}
          textEmpty={textEmpty}
          extraTableStyles={extraTableStyles}
          tableContainerStyle={tableContainerStyle}
          width={width}
          titleMoreButton={titleMoreButton}
          notDisplayMore={notDisplayMore && !displayShowMore}
          showPausedIcon={showPausedIcon}
          highlighted={highlighted}
          trStyle={trStyle}
          rowIsLink={rowIsLink}
          notBottomPadding
          cellBtnHandler={cellBtnHandler}
          isNotScrollWrapper={isNotScrollWrapper}
          cellStyleName={cellStyleName}
          hideTableHeader={hideTableHeader}
          onClickFileTitle={onClickFileTitle}
          updateWorkspaceMembers={updateWorkspaceMembers}
        />
      </Fragment>
    );
  }
}

export default TableBlock;
