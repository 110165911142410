// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOXB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA5IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNTIuMiAoNjcxNDUpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPjY4N0E1QUUzLTY0QjktNENFRi04NjhELUY1QjJFMUE5NTdGNzwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZyBpZD0ibml0cm8tdHJhbnNsYXRvciIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlVJLUtJVC1ERU1PIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzIuMDAwMDAwLCAtMTMxMi4wMDAwMDApIiBmaWxsPSIjNDc0NzQ3Ij4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9InNvcnRpbmctYXJyb3ctdXAiIHBvaW50cz0iMzcgMTMxNC4wODMzMyAzNyAxMzIzIDM2IDEzMjMgMzYgMTMxNC4wODMzMyAzMy41IDEzMTcgMzIgMTMxNyAzNi41IDEzMTIgNDEgMTMxNyAzOS41IDEzMTciPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOXB4IiBoZWlnaHQ9IjExcHgiIHZpZXdCb3g9IjAgMCA5IDExIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IHNrZXRjaHRvb2wgNTIuMiAoNjcxNDUpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkM1QkQyRDc2LUFDRUItNDMxMy05QTVELTg3NDhFQjQ2QzcwNjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZyBpZD0ibml0cm8tdHJhbnNsYXRvciIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlVJLUtJVC1ERU1PIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzAuMDAwMDAwLCAtMTMxMi4wMDAwMDApIiBmaWxsPSIjNDc0NzQ3Ij4KICAgICAgICAgICAgPHBhdGggZD0iTTc1LDEzMjAuOTE2NjcgTDc3LjUsMTMxOCBDNzguNSwxMzE4IDc5LDEzMTggNzksMTMxOCBDNzksMTMxOCA3Ny41LDEzMTkuNjY2NjcgNzQuNSwxMzIzIEw3MCwxMzE4IEw3MS41LDEzMTggTDc0LDEzMjAuOTE2NjcgTDc0LDEzMTIgTDc1LDEzMTIgTDc1LDEzMjAuOTE2NjcgWiIgaWQ9InNvcnRpbmctYXJyb3ctZG93biI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTQgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+QjQyMzMxOTMtRjIwRi00ODFELUEyMDQtNUI0Q0M2MzFCQzY4PC90aXRsZT4KICAgIDxnIGlkPSIt8J+UkS0tbml0cm8tYWRtaW4iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJyYXRlcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYyNy4wMDAwMDAsIC0yNDUuMDAwMDAwKSIgZmlsbD0iIzYxNjE2MSI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik02NDEsMjQ1IEw2NDEsMjQ2IEw2MzUsMjUyIEw2MzUsMjU2IEw2MzMsMjU3IEw2MzMsMjUyIEw2MjcsMjQ2IEw2MjcsMjQ1IEw2NDEsMjQ1IFogTTYzNywyNDggTDYzMSwyNDggTDYzNCwyNTEgTDYzNywyNDggWiIgaWQ9ImZpbHRlciI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTQgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+MjUwNENCQjQtQTJCOS00QkVELUJFOTgtOUE3MTA2MkRCOUNDPC90aXRsZT4KICAgIDxnIGlkPSIt8J+UkS0tbml0cm8tYWRtaW4iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJyYXRlcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc4Ni4wMDAwMDAsIC0yNDUuMDAwMDAwKSIgZmlsbD0iIzFFODhFNSI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik04MDAsMjQ1IEw4MDAsMjQ2IEw3OTQsMjUyIEw3OTQsMjU2IEw3OTIsMjU3IEw3OTIsMjUyIEw3ODYsMjQ2IEw3ODYsMjQ1IEw4MDAsMjQ1IFogTTc5NiwyNDggTDc5MCwyNDggTDc5MywyNTEgTDc5NiwyNDggWiIgaWQ9ImZpbHRlci1jb3B5Ij48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-column__title__gghCxB {
  padding: 8px 12px 11px 0px !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
}

.table-column__group-id-first-column__NXqfWW {
    padding: 8px 12px 11px 8px !important;
    position: relative;
    color: #212121;
    font-size: 14px;
    line-height: 21px !important;
    font-weight: 300;
    border-bottom: 1px solid #e0e0e0 !important;
    box-sizing: border-box;
    width: 108px;
    min-width: 108px;
    max-width: 108px;
}

.table-column__group-id__fHZkMd {
  padding: 8px 12px 11px 0px !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  width: 108px;
  min-width: 108px;
  max-width: 108px;
}

.table-column__idWithoutSorting__sEyEnE {
  padding: 8px 12px 11px 20px;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  width: 108px;
  min-width: 108px;
  max-width: 108px;
}

.table-column__icons__diCzmG {
  padding: 8px 12px 11px 0 !important;
  position: relative;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  width: 76px;
  min-width: 76px;
  /*cursor: pointer;*/
}

.table-column__snippet__sFJ5Bn {
  padding: 8px 12px 11px 0 !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  width: 244px;
  min-width: 244px;
}

.table-column__languages__ZJUEaH {
  padding: 8px 12px 11px 0px !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  /*width: 148px;*/
  /*min-width: 148px;*/
}

.table-column__volume__cvvqdn {
  padding: 8px 12px 11px 0 !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  width: 92px;
  min-width: 92px;
}

.table-column__prices__Nwr194 {
  padding: 8px 12px 11px 0 !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  width: 92px;
  min-width: 92px;

  /* //RC */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-column__time__Yyv29Z {
  padding: 8px 12px 11px 0 !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;

  /*white-space: initial;*/
}

.table-column__formats__GprT0A {
  padding: 8px 12px 11px 0px !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  /*width: 148px;*/
  /*min-width: 148px;*/
  /*cursor: pointer;*/
}

.table-column__personal__cpLbdD {
  padding: 8px 12px 11px 0 !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  width: 244px;
  min-width: 244px;
  overflow: hidden;
  text-overflow: ellipsis;
  /*cursor: pointer;*/
}

.table-column__roles__hByu1U {
  padding: 8px 12px 11px 0px !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
}

.table-column__content__a14xGY {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 16px;
}

.table-column__title-up__Cq2gYp {
}

.table-column__title-up__Cq2gYp::before {
    content: "";
    display: block;
    left: 4px;
    bottom: 17px;
    position: absolute;
    width: 9px;
    height: 11px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
      no-repeat bottom;
  }

.table-column__title-down__xf0vlF {
}

.table-column__title-down__xf0vlF::before {
    content: "";
    display: block;
    left: 4px;
    bottom: 17px;
    position: absolute;
    width: 9px;
    height: 11px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___})
      no-repeat bottom;
  }

.table-column__filter-block__RF_KTo {
  cursor: pointer;
  width: 14px;
  height: 12px;
  /*margin-left: 4px;  //RC*/
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-column__filter__AiA8vD {
  width: 14px;
  height: 12px;
  /*margin-left: 4px;  //RC*/
  margin-left: 3px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___})
    no-repeat;
}

.table-column__appliedFilter__sQjWT1 {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_3___})
    no-repeat;
}

.table-column__column-id__c1sDez {
  width: 15px;
  cursor: pointer;
}

.table-column__column-id__c1sDez > div {
  width: 74px;
  cursor: pointer;
}

.table-column__column-amount__gmIYQN {
  min-width: 218px;
  text-align: right;
}

.table-column__column-amount__gmIYQN > div {
  padding-right: 74px;
}

.table-column__column-created__fWMgXu {
  width: 140px;
  min-width: 140px;
  max-width: 202px;
  cursor: pointer;
}

.table-column__column-status__L8boUw {
  /*  //RC fix it*/
  /*width: 90px;*/
  /*min-width: 100%;*/

  padding-left: 16px !important;
}

.table-column__firstColumnWithIcon__xHu5rN {
  padding: 8px 12px 11px 32px !important;
  position: relative;
  color: #212121;
  font-size: 14px;
  line-height: 21px !important;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
}

.table-column__column-status__L8boUw > div {
  white-space: normal;
  /*cursor: pointer;*/
}

@media (max-width: 658px) {
  .table-column__column-amount__gmIYQN {
    width: 100%;
    min-width: 130px;
  }
  .table-column__column-amount__gmIYQN > div {
    padding-right: 44px;
  }
  .table-column__column-status__L8boUw {
    min-width: 100%;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `table-column__title__gghCxB`,
	"group-id-first-column": `table-column__group-id-first-column__NXqfWW`,
	"group-id": `table-column__group-id__fHZkMd`,
	"idWithoutSorting": `table-column__idWithoutSorting__sEyEnE`,
	"icons": `table-column__icons__diCzmG`,
	"snippet": `table-column__snippet__sFJ5Bn`,
	"languages": `table-column__languages__ZJUEaH`,
	"volume": `table-column__volume__cvvqdn`,
	"prices": `table-column__prices__Nwr194`,
	"time": `table-column__time__Yyv29Z`,
	"formats": `table-column__formats__GprT0A`,
	"personal": `table-column__personal__cpLbdD`,
	"roles": `table-column__roles__hByu1U`,
	"content": `table-column__content__a14xGY`,
	"title-up": `table-column__title-up__Cq2gYp table-column__title__gghCxB`,
	"title-down": `table-column__title-down__xf0vlF table-column__title__gghCxB`,
	"filter-block": `table-column__filter-block__RF_KTo`,
	"filter": `table-column__filter__AiA8vD`,
	"appliedFilter": `table-column__appliedFilter__sQjWT1 table-column__filter__AiA8vD`,
	"column-id": `table-column__column-id__c1sDez`,
	"column-amount": `table-column__column-amount__gmIYQN`,
	"column-created": `table-column__column-created__fWMgXu`,
	"column-status": `table-column__column-status__L8boUw`,
	"firstColumnWithIcon": `table-column__firstColumnWithIcon__xHu5rN`
};
export default ___CSS_LOADER_EXPORT___;
