export var WorkspaceTypes;
(function (WorkspaceTypes) {
    WorkspaceTypes["LIST_WORKSPACES_ADMIN_REQUEST"] = "LIST_WORKSPACES_ADMIN_REQUEST";
    WorkspaceTypes["LIST_WORKSPACES_ADMIN_SUCCESS"] = "LIST_WORKSPACES_ADMIN_SUCCESS";
    WorkspaceTypes["CREATE_WORKSPACE_REQUEST"] = "CREATE_WORKSPACE_REQUEST";
    WorkspaceTypes["CREATE_WORKSPACE_SUCCESS"] = "CREATE_WORKSPACE_SUCCESS";
    WorkspaceTypes["GET_WORKSPACES_ADMIN_REQUEST"] = "GET_WORKSPACES_ADMIN_REQUEST";
    WorkspaceTypes["GET_WORKSPACES_ADMIN_SUCCESS"] = "GET_WORKSPACES_ADMIN_SUCCESS";
    WorkspaceTypes["GET_WORKSPACE_REQUEST"] = "GET_WORKSPACE_REQUEST";
    WorkspaceTypes["GET_WORKSPACE_SUCCESS"] = "GET_WORKSPACE_SUCCESS";
    WorkspaceTypes["GET_WORKSPACE_DISABLE"] = "GET_WORKSPACE_DISABLE";
    WorkspaceTypes["UPDATE_ADMIN_WORKSPACE_REQUEST"] = "UPDATE_ADMIN_WORKSPACE_REQUEST";
    WorkspaceTypes["UPDATE_ADMIN_WORKSPACE_SUCCESS"] = "UPDATE_ADMIN_WORKSPACE_SUCCESS";
    WorkspaceTypes["LIST_WORKSPACE_NAMES_SUCCESS"] = "LIST_WORKSPACE_NAMES_SUCCESS";
    WorkspaceTypes["LIST_WORKSPACE_NAMES_REQUEST"] = "LIST_WORKSPACE_NAMES_REQUEST";
    WorkspaceTypes["ACCEPT_INVITE_REQUEST"] = "ACCEPT_INVITE_REQUEST";
    WorkspaceTypes["ACCEPT_INVITE_SUCCESS"] = "ACCEPT_INVITE_SUCCESS";
    WorkspaceTypes["ACCEPT_INVITE_ERROR"] = "ACCEPT_INVITE_ERROR";
    WorkspaceTypes["DELETE_INVITE_REQUEST"] = "DELETE_INVITE_REQUEST";
    WorkspaceTypes["DELETE_INVITE_SUCCESS"] = "DELETE_INVITE_SUCCESS";
    WorkspaceTypes["DELETE_INVITE_ERROR"] = "DELETE_INVITE_ERROR";
    WorkspaceTypes["ERROR_UPDATE_WORKSPACE_ADMIN"] = "ERROR_UPDATE_WORKSPACE_ADMIN";
    WorkspaceTypes["ERROR_ADD_INVITES_ADMIN"] = "ERROR_ADD_INVITES_ADMIN";
    WorkspaceTypes["SUCCESS_ADD_INVITES_ADMIN"] = "SUCCESS_ADD_INVITES_ADMIN";
    WorkspaceTypes["REQUEST_ADD_INVITES_ADMIN"] = "REQUEST_ADD_INVITES_ADMIN";
    WorkspaceTypes["ERROR_DELETE_USERS_ADMIN"] = "ERROR_DELETE_USERS_ADMIN";
    WorkspaceTypes["SUCCESS_DELETE_USERS_ADMIN"] = "SUCCESS_DELETE_USERS_ADMIN";
    WorkspaceTypes["REQUEST_DELETE_USERS_ADMIN"] = "REQUEST_DELETE_USERS_ADMIN";
    WorkspaceTypes["ERROR_UPDATE_USERS_WORKSPACE_ADMIN"] = "ERROR_UPDATE_USERS_WORKSPACE_ADMIN";
    WorkspaceTypes["SUCCESS_UPDATE_USERS_WORKSPACE_ADMIN"] = "SUCCESS_UPDATE_USERS_WORKSPACE_ADMIN";
    WorkspaceTypes["REQUEST_UPDATE_USERS_WORKSPACE_ADMIN"] = "REQUEST_UPDATE_USERS_WORKSPACE_ADMIN";
    WorkspaceTypes["SUCCESS_UPDATE_EMAIL_NOTIFICATIONS"] = "SUCCESS_UPDATE_EMAIL_NOTIFICATIONS";
    WorkspaceTypes["ERROR_UPDATE_EMAIL_NOTIFICATIONS"] = "ERROR_UPDATE_EMAIL_NOTIFICATIONS";
    WorkspaceTypes["REQUEST_UPDATE_EMAIL_NOTIFICATIONS"] = "REQUEST_UPDATE_EMAIL_NOTIFICATIONS";
    WorkspaceTypes["ERROR_UPDATE_WORKSPACE_MEMBER"] = "ERROR_UPDATE_WORKSPACE_MEMBER";
    WorkspaceTypes["SUCCESS_UPDATE_WORKSPACE_MEMBER"] = "SUCCESS_UPDATE_WORKSPACE_MEMBER";
    WorkspaceTypes["REQUEST_UPDATE_WORKSPACE_MEMBER"] = "REQUEST_UPDATE_WORKSPACE_MEMBER";
    WorkspaceTypes["ERROR_DELETE_WORKSPACE_MEMBERS"] = "ERROR_DELETE_WORKSPACE_MEMBERS";
    WorkspaceTypes["SUCCESS_DELETE_WORKSPACE_MEMBERS"] = "SUCCESS_DELETE_WORKSPACE_MEMBERS";
    WorkspaceTypes["REQUEST_DELETE_WORKSPACE_MEMBERS"] = "REQUEST_DELETE_WORKSPACE_MEMBERS";
    WorkspaceTypes["ERROR_UPDATE_USERS_ADMIN"] = "ERROR_UPDATE_USERS_ADMIN";
    WorkspaceTypes["SUCCESS_UPDATE_USERS_ADMIN"] = "SUCCESS_UPDATE_USERS_ADMIN";
    WorkspaceTypes["REQUEST_UPDATE_USERS_ADMIN"] = "REQUEST_UPDATE_USERS_ADMIN";
    WorkspaceTypes["REQUEST_INVITE_INFO"] = "REQUEST_INVITE_INFO";
    WorkspaceTypes["SUCCESS_INVITE_INFO"] = "SUCCESS_INVITE_INFO";
    WorkspaceTypes["ERROR_INVITE_INFO"] = "ERROR_INVITE_INFO";
})(WorkspaceTypes || (WorkspaceTypes = {}));
